import React from 'react';

class SearchBar extends React.Component{

    state = {term: 'React Tutorial'};

    onInputChange = (event) => {

        this.setState({term: event.target.value});

    };

    onFormSubmit = (event) => {
       event.preventDefault();

       this.props.onFormSubmit(this.state.term);
    };

    render(){

        return(
            <div className='search-bar ui segment'>
                <form onSubmit={this.onFormSubmit} className='ui form'>
                    <div className='ui field'>
                        <label>
                            Video Search
                        </label>
                        <input value={this.state.term} type='text' onChange={this.onInputChange} />

                    </div>
                </form>
            </div>
        );

    }
       
}

export default SearchBar;